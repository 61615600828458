.features-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 36px -23px 0;
  flex-wrap: wrap;

  li {
    width: calc(100% - 46px);
    margin: 0 23px;
    padding: 33px 10px 28px 40px;
    border-top: 1px solid $color-very-light-blue;
    position: relative;

    @include mq('tablet') {
      width: calc(50% - 46px);
    }

    @include mq('desktop-sm') {
      width: calc(33.3333% - 46px);
    }

    &:nth-child(n+4) {
      border-bottom: 1px solid $color-very-light-blue;
    }

    &::before {
      width: 18px;
      height: 15px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url('../images/industry-list-icon.svg') 0 0 no-repeat;
      content: '';
    }
  }
}

.dowloand-list {
  display: flex;
  list-style: none;
  padding: 120px 0 160px;
  margin: -30px -15px 0;
  flex-wrap: wrap;
  align-items: flex-start;

  li {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    margin: 30px 15px 0;
    background-color: $color-very-light-blue;
    border-radius: 8px;

    @include mq('tablet') {
      width: calc(50% - 30px);
    }

    h3 {
      display: inline-block;
      width: 100%;
      padding: 37px 70px 38px 40px;
      position: relative;
      border-radius: 8px;
      background-color: $color-very-light-blue;
      color: $color-dark-three;
      position: relative;
      font-size: 22px;
      font-weight: 600;
      line-height: 1.09;
      cursor: pointer;

      &::before {
        width: 12px;
        height: 16px;
        position: absolute;
        right: 47px;
        top: 50%;
        transform: translateY(-50%);
        background: url('../images/dowloand-icon.svg') 0 0 no-repeat;
        content: '';
        transition: all 0.3s;
      }
    }

    .active {
      &::before {
        transform: rotate(180deg) translateY(50%);
        transition: all 0.2s;
      }
    }
  }

  .active + .course-panel {
    margin-bottom: 30px;
  }
}

.industry {
  &-benefits {
    padding: 136px 0 160px;
  }
}

.list-tariffs {
  list-style: none;
  padding: 0;
  margin: 0 -15px 140px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &-br {
    margin: 24px 0;
    height: 1px;
    background-color: $color-cloudy-blue;
  }

  &-links {
    min-height: 32px;
  }

  >li {
    position: relative;
    padding: 44px 40px;
    width: calc(33.3333% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 8px;
    box-shadow: 0 30px 200px 0 rgba(18, 21, 27, 0.08);
    background-color: $color-white;
    margin-bottom: 30px;

    @include mq('tablet', max) {
      width: calc(100% - 30px);
    }

    &:first-child {
      background-image: linear-gradient(246deg, $color-dusty-orange, $color-pinky-red);
      box-shadow: inherit;
      color: $color-white;

      span {
        opacity: 0.6;
        color: $color-white;
      }

      h4,
      h3 {
        color: $color-white;
        background-clip: inherit;
        background-image: none;
        -webkit-text-fill-color: inherit;
      }

      .list-tariffs-br {
        background-color: $color-white;
        opacity: 0.6;
      }

      .list-tariffs-link {
        opacity: 0.6;
        color: $color-white !important;

        &:hover {
          opacity: 1;
        }
      }
    }

    h3 {
      font-size: 40px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 24px;
      background-clip: text;
      -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(to right, $color-pinky-red-two, $color-dusty-orange-three 64%);
    }

    h4 {
      margin-bottom: 24px;
      min-height: 32px;
      background-clip: text;
      -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(to right, $color-pinky-red-two, $color-dusty-orange-three 64%);
    }

    span {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.09;
      color: $color-cloudy-blue;
    }
  }

  &-link {
    cursor: pointer;
    display: inline-block;
    border: 0;
    background-color: transparent;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.09;
    color: $color-cloudy-blue;
    padding: 0;
    margin: 0;
    transition: 0.25s;
    position: relative;
    z-index: 2;

    &:hover {
      color: $color-pinky-red-two;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &-link-wrap {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-indent: -9999px;
    cursor: pointer;
    z-index: 1;
    border: 0;
    background-color: transparent;
  }
}

.industry-list {
  margin-bottom: 95px;

  .home-hiw {
    &-listItem {
      &::before {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        content: '';
      }

      &:nth-child(1)::before,
      &:nth-child(1n+3)::before {
        background-image: url('../images/arrow-icon1.svg');
      }

      &:nth-child(2)::before,
      &:nth-child(2n+3)::before {
        background-image: url('../images/arrow-icon2.svg');
      }

      &:nth-child(3n+3)::before {
        background-image: url('../images/arrow-icon3.svg');
      }
    }
  }
}

.course-panel {
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  width: 100%;
  padding: 0 70px 0 40px;
}

.is-flip {
  .double {
    &__content-item {
      &:first-child {
        order: 2;
      }

      &-image {
        text-align: right;
      }

      .text-block {
        text-align: left;
        margin-bottom: 30px;
      }
    }
  }
}

.copy-block {
  img {
    max-width: 400px;
  }
}

.section-vert {
  display: flex;
  flex-wrap: wrap;

  &-bottom {
    width: 100%;
    align-self: flex-end;
  }

  &-top {
    width: 100%;
  }
}


