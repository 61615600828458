.hero {
  &-cover {
    margin-bottom: 99px;
    position: relative;
    z-index: 9;

    @media (max-width: 1199px) {
      margin-bottom: 80px;
    }

    @media (max-width: 991px) {
      margin-bottom: 60px;
    }

    @media (max-width: 374px) {
      margin-bottom: 40px;
    }

    &-container {
      @media (min-width: 1441px) {
        width: 1350px;
      }
    }

    .no-image {
      .hero-cover-content {
        max-width: 726px;
      }

      .hero-cover-title {
        font-size: 38px;

        @include mq('tablet') {
          font-size: 70px;
        }
      }

      .hero-cover-inner::after {
        right: -67.1em;
        top: -21.8em;
      }
    }

    &-bg {
      position: absolute;
      width: 2016px;
      min-width: 140%;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      border-bottom-left-radius: 100% 200px;
      border-bottom-right-radius: 100% 200px;
      overflow: hidden;
      background: $color-dark-three;

      img {
        width: 100vw;
        height: 100%;
        object-fit: cover;
        object-position: bottom center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.3;
      }
    }

    &-inner {
      padding-top: 160px;
      padding-bottom: 100px;
      position: relative;
      z-index: 1;

      @media (max-width: 1199px) {
        padding-bottom: 90px;
        min-height: 100%;
      }

      @media (max-width: 991px) {
        padding-top: 140px;
        padding-bottom: 70px;
      }

      @media (max-width: 767px) {
        padding-top: 100px;
        padding-bottom: 40px;
      }

      @media (max-width: 374px) {
        padding-top: 80px;
        padding-bottom: 30px;
      }

      &.home-page-inner {
        min-height: 680px;
      }

      &.industry-inner {
        min-height: 600px;
        display: flex;
        gap: 40px;
        align-items: center;

        @media (max-width: 991px) {
          gap: 32px;
        }

        @media (max-width: 767px) {
          padding-top: 240px;
          flex-direction: column;
          gap: 24px;
        }

        @media (max-width: 374px) {
          gap: 16px;
        }
      }

      &::after {
        content: '';
        width: 91.7em;
        height: 91.7em;
        display: block;
        position: absolute;
        right: -56.1em;
        top: 5.2em;
        border-radius: 50%;
        background-image: url('../images/cover-circles.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        z-index: -2;
        font-size: 10px;

        @media (max-width: 1199px) {
          font-size: 9px;
        }

        @media (max-width: 991px) {
          display: none;
        }
      }
    }

    &-content {
      max-width: 486px;
      width: 100%;
      position: relative;
      z-index: 2;

      @media (max-width: 1199px) {
        max-width: 450px;
        width: unset;
      }

      @media (max-width: 991px) {
        max-width: 380px;
      }

      @media (max-width: 767px) {
        max-width: unset;
        margin-bottom: 40px;
      }

      @media (max-width: 374px) {
        margin-bottom: 40px;
      }
    }

    &-image-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;

      &.home-page-image-wrapper {
        position: absolute;
        right: 0;
        height: 100%;
        top: 0;

        @include mq('tablet', max) {
          left: 0;
          right: 0;
          width: 100% !important;
        }

        @media (max-width: 767px) {
          position: relative;
          right: unset;
          top: unset;
          display: inline-block;
        }
      }
    }

    &-image {
      margin-top: 3.8em;
      border-radius: 1em;
      overflow: hidden;
      //box-shadow: 0 3em 20em 0 rgba(18, 21, 27, 0.3);
      //z-index: -1;
      font-size: 10px;

      &.industry-page-image {
        margin-top: 0;
      }

      &.home-page-image {
        margin-top: 40px;
        width: 340px;
        height: 620px;

        @include mq('tablet', max) {
          margin-top: 0;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }

      img,
      video {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 1em;
      }

      @media (max-width: 1199px) {
        font-size: 9px;
      }

      @media (max-width: 991px) {
        font-size: 7.5px;
        margin-top: 4.8em;
      }

      @media (max-width: 767px) {
        position: relative;
        margin-top: 0;
        width: 100%;
        font-size: 5.74px;
      }

      @media (max-width: 374px) {
        font-size: 4.675px;
        width: 100%;
      }
    }

    &-title {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 50px;

      @media (max-width: 991px) {
        margin-bottom: 25px;
      }

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    &-text {
      margin-bottom: 24px;
      max-width: 499px;

      @media (max-width: 991px) {
        margin-bottom: 35px;
      }

      @media (max-width: 767px) {
        max-width: unset;
        padding-right: 20px;
        margin-bottom: 27px;
      }

      @media (max-width: 374px) {
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 0;
        font-family: $ff-tt-font;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.82;
        letter-spacing: normal;
        color: $color-white;

        @media (max-width: 1199px) {
          font-size: 20px;
        }

        @media (max-width: 991px) {
          font-size: 18px;
        }

        @media (max-width: 767px) {
          line-height: 1.5;
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    &-hiw_btn {
      margin-right: 21px;
      padding-left: 74px !important;
      position: relative;

      &::before {
        width: 12px;
        height: 13px;
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        background: url('../images/icon-hiw.svg') 0 0 no-repeat;
        content: '';
      }

      img {
        margin-right: 15px;
        margin-top: -2px;
      }

      @media (max-width: 991px) {
        margin-right: 15px;
        padding-left: 34px !important;

        &::before {
          left: 20px;
        }

        img {
          margin-right: 10px;
          margin-top: -4px;
        }
      }
    }
  }

  &-cover-light {
    .hero-cover-title {
      color: $color-dark-three;
    }
  }
}

