/**
 * Footer
 */

.part-footer {
  position: relative;
  padding-top: 3vw;
  z-index: 9;

  .container {
    position: relative;
    z-index: 999;
    background: $color-dark-three;
  }

  .home-benefits-bg-circle {
    display: none;
  }

  &__cta {
    display: flex;

    > .button + .button {
      margin-left: 14px;
    }
  }

  &__bg {
    z-index: -1;
    background-color: $color-dark-three;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    display: block;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 3456px;
      min-width: 240%;
      height: 0;
      padding-bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(100% - 3vw);
      border-bottom-left-radius: 100% 50%;
      border-bottom-right-radius: 100% 50%;
      overflow: hidden;
      background-color: $color-white;
    }
  }

  &__wrapper {
    padding-top: 133px;
    padding-bottom: 81px;

    @media (max-width: 1199px) {
      padding-top: 100px;
      padding-bottom: 50px;
    }

    @media (max-width: 991px) {
      padding-top: 80px;
      padding-bottom: 70px;
    }

    @media (max-width: 499px) {
      padding-top: 58px;
      padding-bottom: 46px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 101px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    @media (max-width: 991px) {
      padding-bottom: 80px;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 45px;
    }

    @media (max-width: 374px) {
      padding-bottom: 30px;
    }
  }

  &__content {

    @media (max-width: 1199px) {
      max-width: 594px;
    }

    @media (max-width: 991px) {
      max-width: 415px;
    }

    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 21px;
    font-family: $ff-tt-font;
    font-size: 50px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $color-white;

    @media (max-width: 1199px) {
      font-size: 46px;
      margin-bottom: 15px;
    }

    @media (max-width: 991px) {
      font-size: 36px;
      margin-bottom: 8px;
    }

    @media (max-width: 499px) {
      font-size: 30px;
      margin-bottom: 10px;
    }

    @media (max-width: 374px) {
      font-size: 26px;
      margin-bottom: 5px;
    }
  }

  &__text {
    p {
      margin-top: 0;
      margin-bottom: 0;
      font-family: $ff-tt-font;
      font-size: 22px;
      font-weight: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: $color-grey-blue;
    }

    a {
      color: $color-white;
      text-decoration: none;

      &:hover {
        color: $color-orange-two;
      }
    }

    @media (max-width: 991px) {
      p {
        font-size: 20px;
      }
    }

    @media (max-width: 499px) {
      p {
        font-size: 16px;
      }

      a {
        display: inline-block;
      }
    }

    @media (max-width: 374px) {
      p {
        font-size: 14px;
      }
    }
  }

  &__copyright {
    padding-top: 45px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 991px) {
      padding-top: 30px;
      flex-direction: column;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-nav {
      display: grid;
      grid-template-columns: repeat(4, auto);
      padding-top: 5px;
      grid-column-gap: 40px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      @media (max-width: 991px) {
        grid-column-gap: 32px;
      }

      @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 20px;
        grid-column-gap: 80px;
      }

      @media (max-width: 499px) {
        padding-top: 0;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }
    }

    li,
    p {
      font-family: $ff-tt-font;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: $color-grey-blue;

      a {
        color: inherit;
      }

      a:hover {
        color: $color-white;
      }

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

  }

  &__logo {
    width: 115px;
    height: auto;
    display: inline-block;

    img {
      filter: brightness(0) invert(1);
      max-width: 100%;
    }

    @media (max-width: 1199px) {
      margin-right: 40px;
    }

    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 25px;
    }

    @media (max-width: 767px) {
      width: 100px;
      margin-bottom: 20px;
    }
  }
}
