// Media Query Sizes
@mixin tablet {
  @media screen and (min-width: #{$mqw-tablet}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$mqw-desktop}) {
    @content;
  }
}

@mixin sitewidth {
  @media screen and (min-width: #{$mqw-wrapper + ($grid-offset-desktop * 2)}) {
    @content;
  }
}

// No Touch Devices
@mixin notouch {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}


@mixin large-desktop {

  @media (min-width: 1440px) {
    @content;
  }

}

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type==max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
