.page-light {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 207px;
    height: 293px;
    background-image: url('../images/bg-round.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;

    @include mq('tablet') {
      width: 515px;
      height: 600px;
    }
  }
}
