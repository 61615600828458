.hero-simple {
  padding-top: 160px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;

  @include mq('tablet') {
    padding-top: 184px;
    padding-bottom: 108px;
  }

  &-title {
    color: $color-dark-three;
    line-height: 1;
    font-weight: 600;
    font-size: 40px;

    @include mq('tablet') {
      font-size: 70px;
    }
  }

  &-title-short {
    max-width: 726px;
  }
}

