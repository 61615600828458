/**
 * Header
 */
$parent: '.header';

.header {
  height: auto;
  position: absolute;
  // position: fixed;
  // display: none;
  top: 0;
  left: 0;
  right: 0;
  transition: $transition;
  background: transparent;
  z-index: 99;

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 41px 45px 41px 46px;
    transition: $transition;
    z-index: 1;

    @media (min-width: 1441px) {
      max-width: 1411px;
      margin: 0 auto;
    }

    @media (max-width: 1280px) {
      padding: 30px 25px;
    }

    @media (max-width: 1199px) {
      padding: 25px 15px;
    }

    @media (max-width: 991px) {
      align-items: center;
      padding: 20px 0;
    }

    @media (max-width: 767px) {
      padding: 15px 0;
    }
  }

  &__logo {
    position: relative;
    width: 13.6em;
    height: auto;
    display: block;
    margin-top: -12px;
    font-size: 10px;
    transition: none;
    margin-right: auto;

    img,
    svg {
      display: block;
      width: 100%;
      height: auto;
    }

    @media (max-width: 1199px) {
      margin-top: -10px;
    }

    @media (max-width: 767px) {
      margin-top: -4px;

      font-size: 8px;
    }
  }

  &-mobile-menu {
    @media (min-width: 992px) {
      display: none;
    }

    display: block;
    width: 24px;
    height: 16px;
    position: absolute;
    top: 32px;
    right: 0;
    z-index: 100;
    transition: none;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 20px;
      height: 14px;
      top: 24px;
    }

    .line {
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      left: 0;
      background: $color-white;
      transition: all $transition ease-in-out;
    }

    .line-1 {
      top: 0;
    }

    .line-2 {
      top: 50%;

      transform: translateY(-50%);
    }

    .line-3 {
      bottom: 0;
    }

    &.opened {
      .line {
        background: $color-dark-three;
      }

      .line-1 {
        top: 7px;

        transform: rotate(45deg);
      }

      .line-2 {
        opacity: 0;
      }

      .line-3 {
        bottom: 7px;

        transform: rotate(-45deg);
      }

      @media (max-width: 991px) {
        position: fixed;
        right: 20px;
      }

      @media (max-width: 767px) {
        .line-1 {
          top: 6px;
        }

        .line-3 {
          bottom: 6px;
        }
      }

      @media (max-width: 374px) {
        right: 15px;
      }
    }
  }

  &__nav {
    &-menu {
      display: flex;
      align-items: center;

      &-list {
        display: flex;
        list-style: none;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 0;
        margin: 2px 0 0;
      }

      @media (max-width: 991px) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: 0 40px;
        align-items: flex-start;
        background-color: $color-white;
        overflow-y: auto;
        z-index: 1;

        &-list {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 0;
          width: 100%;
          padding: 70px 0;
          height: auto;
          padding-bottom: 40px;
          border-bottom: 1px solid $color-very-light-blue;
        }
      }

      @media (max-width: 767px) {
        &-list {
          padding: 56px 0 29px;
        }
      }

      li {
        display: inline-block;
        margin-right: 69.5px;
        line-height: normal;

        @media (max-width: 1199px) {
          margin-right: 58px;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          display: inline-block;
          transition: $transition;
          color: $color-white;
          font-family: $ff-tt-font;
          font-size: 18px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        a:hover {
          color: $color-orange-two;
        }
      }

      li a {
        @media (max-width: 991px) {
          display: inline;
          font-size: 30px;
          line-height: 2.7;
          color: #292f3b;
        }
      }
    }

    /* stylelint-disable max-nesting-depth */

    @media (max-width: 991px) {
      .menu-item-children {
        display: none;
      }

      .menu-item--has-children {
        position: relative;

        .parent-link {
          position: relative;
        }

        .parent-link::before {
          content: '';
          position: absolute;
          right: -36px;
          top: 8px;
          z-index: 1;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 0;
          border-color: #292f3b transparent transparent;
        }

        &.is-active {
          .parent-link::before {
            transform: rotate(180deg);
          }

          .menu-item-children {
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            position: relative;
          }
        }
      }

      .menu-item-children-item + .menu-item-children-item {
        margin-top: 16px;
      }


      .menu-item-children-link {
        font-size: 24px;
        line-height: 1.5;
      }
    }

    @media (min-width: 992px) {
      .menu-item-children {
        top: 38px;
        left: -10px;
        background-color: #fff;
        padding: 16px 10px;
        border-radius: 4px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        transition: 0.35s ease;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        position: absolute;

        &::before {
          content: '';
          position: absolute;
          z-index: 1;
          top: -6px;
          left: 16px;
          display: block;
          border-style: solid;
          border-width: 0 8px 8px;
          border-color: transparent transparent #fff;
        }
      }

      .menu-item--has-children {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          right: -20px;
          top: 8px;
          z-index: 1;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 4px 0;
          border-color: #fff transparent transparent;
        }

        &:hover { /* ignored regardless of options */
          .menu-item-children {
            z-index: 1;
            opacity: 1;
            visibility: visible;
          }

          &::before {
            transform: rotate(180deg);
          }
        }

        .menu-item-children-link {
          color: #626e84;
          white-space: nowrap;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 8px 4px;
          font-size: 16px;
          line-height: 1;

          &:hover {
            color: #f37c22;
          }
        }
      }
    }
    /* stylelint-enable max-nesting-depth */
  }

  .button {
    margin-left: 37px;

    @media (max-width: 1199px) {
      font-size: 16px;
      margin-left: 40px;
      padding-top: 12px;
      padding-bottom: 9px;
      line-height: normal;
    }

    @media (max-width: 991px) {
      margin-left: 0;
      margin-right: 44px;
    }

    @media (max-width: 767px) {
      margin-right: 35px;
      padding-top: 9px;
      padding-bottom: 6px;

      font-size: 14px;
    }
  }

  .button + .button {
    margin-left: 14px;
  }

  .lang-switcher-wrapper {
    margin-left: auto;
    margin-right: 30px;

    @include mq('tablet-wide') {
      margin-right: auto;
      margin-left: 70px;
      margin-top: -2px;
    }
  }

  // &.header-init {
  // 	position: fixed;
  // 	top: -70px;
  // 	background-color: $color-dark-three;

  // 	@media (max-width: 374px) {
  // 		// top: -74px;
  // 	}

  // 	.header__inner {
  // 		padding: 15px 45px;

  // 		@media (max-width: 1280px) {
  // 			padding: 15px 25px;
  // 		}

  // 		@media (max-width: 1199px) {
  // 			padding: 15px 15px;
  // 		}

  // 		@media (max-width: 991px) {
  // 			padding: 15px 0;
  // 		}

  // 		@media (max-width: 767px) {
  // 			padding: 17px 0;
  // 		}
  // 	}

  // 	.header-mobile-menu {
  // 		top: 28px;

  // 		@media (max-width: 767px) {
  // 			top: 30px;
  // 		}
  // 	}
  // }

  /*=====  End of Init state  ======*/

  /*======================================
  =            Scrolled state            =
  ======================================*/

  // &.header-fixed {
  // 	transition: $transition;
  // }

  /*=====  End of Scrolled state  ======*/

  &.header-scrolled {
    top: 0;
    position: fixed;
    transition: $transition;
    box-shadow: 0 -60px 50px 30px rgba(92, 60, 182, 0.4);
    background-color: $color-dark-three;

    .header {
      &__inner {
        padding: 15px 45px 15px 46px;

        @media (max-width: 1280px) {
          padding: 15px 25px;
        }

        @media (max-width: 1199px) {
          padding: 15px;
        }

        @media (max-width: 991px) {
          padding: 20px 0;
        }

        @media (max-width: 767px) {
          padding: 15px 0;
        }
      }
    }
  }

  &.header-inversed {
    #{$parent}__nav-menu li a {
      color: $color-dark-three;
    }

    #{$parent}__nav-menu li a:hover {
      color: $color-orange-two;
    }

    .header-mobile-menu .line {
      background-color: $color-dark-three;
    }

    &.header-scrolled {
      background: $color-white;
    }
  }
}

.header-opened {
  .button.button-white {
    text-decoration: none;
    position: relative;
    z-index: 2;
    color: $color-dark-three;
    background-color: rgba($color-dark-three, 0.2);

    &:hover {
      background-color: rgba($color-dark-three, 0.3);
    }

    &:active,
    &:focus {
      background-color: rgba($color-dark-three, 0.4);
    }
  }
}
