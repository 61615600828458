@use 'sass:map';

// Fonts
$fonts-dir: '../fonts';
$fonts-format: ('woff2', 'woff');

$fonts-array: (
  // Fonts - Open Sans
  'TTCommons': (
    fileName: 'TTCommons',
    variants: (
      'light' : 300,
      'regular' : 400,
      'medium' : 500,
      'semibold' : 600,
      'bold' : 700,
    ),
  ),
  // Fonts - Another Font
  'Calibre': (
    fileName: 'calibre',
    variants: (
      'regular' : 400,
      'semibold' : 600,
      'bold' : 900,
    ),
  ),
);

// Source string generation
@function url-string($formats, $font-filename, $font-weight-name) {
  $src: null;

  @each $format in $formats {
    $src: append($src, url('#{$fonts-dir}/#{$font-filename}-#{$font-weight-name}.#{$format}') format($format), comma);
  }

  @return $src;
}

// Generate @font-face
@each $font-name, $font-object in $fonts-array {
  $font-filename: map.get($font-object, 'fileName');
  $font-variants: map.get($font-object, 'variants');

  @each $font-weight-name, $font-weight in $font-variants {
    @font-face {
      font-display: swap;
      font-family: $font-name;
      font-style: normal;
      font-weight: $font-weight;
      src: url-string($fonts-format, $font-filename, $font-weight-name);
    }
  }
}
