.home {
  /*====================================
  =            How it works            =
  ====================================*/

  &-hiw {
    &-inner {
      border-bottom: 1px solid $color-very-light-blue;
      padding-bottom: 99px;

      @media (max-width: 1199px) {
        padding-bottom: 80px;
      }

      @media (max-width: 991px) {
        padding-bottom: 60px;
      }

      @media (max-width: 767px) {
        padding-bottom: 50px;
      }

      @media (max-width: 374px) {
        padding-bottom: 30px;
      }
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }

    &-title {
      margin-bottom: 24px;

      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px 120px;
      padding-right: 33px;
      counter-reset: counter;

      @media (max-width: 1199px) {
        padding-right: 0;
        grid-column-gap: 40px;
      }

      @media (max-width: 991px) {
        display: block;
      }
    }

    &-listItem {
      counter-increment: counter;

      @media (max-width: 991px) {
        &:not(:last-child) {
          margin-bottom: 60px;
        }
      }

      @media (max-width: 767px) {
        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }

      @media (max-width: 374px) {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }

      &::before {
        content: counter(counter);
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 6px;
        margin-bottom: 37px;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: normal;
        color: $color-pinky-red;
        border-radius: 50%;
        background-color: transparentize($color-pinky-red, 0.88);

        @media (max-width: 991px) {
          margin-bottom: 30px;
        }

        @media (max-width: 767px) {
          margin-bottom: 25px;
        }

        @media (max-width: 374px) {
          width: 50px;
          height: 50px;
          margin-bottom: 20px;

          font-size: 20px;
        }
      }

      &:nth-child(1),
      &:nth-child(1n+3) {
        &::before {
          color: $color-pinky-red;
          background-color: transparentize($color-pinky-red, 0.88);
        }
      }

      &:nth-child(2),
      &:nth-child(2n+3) {
        &::before {
          color: $color-pale-red;
          background-color: transparentize($color-pale-red, 0.88);
        }
      }

      &:nth-child(3n+3) {
        &::before {
          color: $color-orange-two;
          background-color: transparentize($color-orange-two, 0.88);
        }
      }
    }

    &-listItem-title {
      margin-top: 0;
      margin-bottom: 9px;
      font-family: $ff-tt-font;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.14;
      letter-spacing: normal;
      color: $color-dark-three;

      @media (max-width: 991px) {
        // min-height: unset;
      }

      @media (max-width: 767px) {
        font-size: 28px;
        font-weight: 500;
      }

      @media (max-width: 499px) {
        font-size: 24px;
      }
    }

    &-listItem-text {
      p {
        font-family: $ff-tt-font;
        font-size: 18px;
        font-weight: normal;
        line-height: 2;
        letter-spacing: normal;
        color: $color-grey-blue;
      }

      @media (max-width: 767px) {
        p {
          font-size: 16px;
        }
      }
    }
  }


  /*=====  End of How it works  ======*/


  /*================================
  =            Benefits            =
  ================================*/

  &-benefits {
    position: relative;
    padding-top: 3vw;
    padding-bottom: 3vw;
    margin-bottom: 141px;
    z-index: 2;

    @media (max-width: 1199px) {
      margin-bottom: 120px;
    }

    @media (max-width: 767px) {
      margin-bottom: 90px;
    }

    @media (max-width: 499px) {
      margin-bottom: 70px;
    }

    &-bg {
      z-index: -1;
      background-color: $color-pale-grey-three;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      display: block;

      &-circle {
        width: 91.7em;
        height: 91.7em;
        display: block;
        position: absolute;
        right: -37.1em;
        top: -49.2em;
        border-radius: 50%;
        background-image: url('../images/cover-circles.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        z-index: -1;
        font-size: 10px;

        @media (max-width: 1199px) {
          font-size: 9px;
        }

        @media (max-width: 991px) {
          display: none;
        }
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 3456px;
        min-width: 240%;
        height: 0;
        padding-bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(100% - 3vw);
        border-bottom-left-radius: 100% 50%;
        border-bottom-right-radius: 100% 50%;
        overflow: hidden;
        background-color: $color-white;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 3456px;
        min-width: 240%;
        height: 0;
        padding-bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% - 3vw);
        border-top-left-radius: 100% 50%;
        border-top-right-radius: 100% 50%;
        overflow: hidden;
        background-color: $color-white;
      }
    }

    &-inner {
      display: grid;
      grid-template-columns: 356px 550px;
      justify-content: space-between;
      padding-top: 92px;

      @media (max-width: 991px) {
        padding-top: 60px;
        display: block;
      }

      @media (max-width: 767px) {
        padding-top: 50px;
      }
    }

    &-content {
      padding-top: 12px;

      @media (max-width: 767px) {
        padding-top: 0;
      }
    }

    &-title {
      margin-bottom: 21px;

      @media (max-width: 991px) {
        margin-bottom: 15px;
      }
    }

    &-text {
      @media (max-width: 991px) {
        margin-bottom: 40px;
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
        font-family: $ff-tt-font;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.82;
        letter-spacing: normal;
        color: $color-grey-blue;

        @media (max-width: 1199px) {
          font-size: 20px;
        }

        @media (max-width: 991px) {
          font-size: 18px;
        }

        @media (max-width: 767px) {
          line-height: 1.5;
        }
      }
    }

    &-cards {
      display: grid;
      grid-template-columns: repeat(2, 260px);
      grid-gap: 30px;
      margin-bottom: -45px;

      @media (max-width: 991px) {
        margin-bottom: -30px;
        grid-template-columns: repeat(2, 353px);
        grid-gap: 22px;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin-bottom: -35px;
      }
    }

    &-card-icon {
      background-color: transparentize($color-white, 0.8);

      img {
        filter: brightness(0) invert(1);
      }

      &::before {
        opacity: 0;
      }
    }

    &-card {
      min-height: 210px;
      position: relative;
      padding: 40px 40px 36px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $color-white;
      border-radius: 8px;
      box-shadow: 0 30px 200px 0 rgba(18, 21, 27, 0.08);
      z-index: 1;
      overflow: hidden;

      @media (max-width: 991px) {
        min-height: 194px;
        padding: 30px 40px 29px;
      }

      @media (max-width: 767px) {
        min-height: 186px;
        padding: 30px 25px 29px;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-image: linear-gradient(231deg, $color-dusty-orange, $color-pinky-red);
        opacity: 0;
        transition: opacity $transition;
      }

      &:first-child {
        &::before {
          opacity: 1;
        }

        .home-benefits-card-title {
          color: $color-white;
          background-image: none;
          background-clip: inherit;
          -webkit-text-fill-color: inherit;
        }

        img {
          filter: brightness(0) invert(1);
        }

        .home-benefits-card-icon::before {
          opacity: 0;
        }
      }

      &-title {
        background-image: linear-gradient(to right, $color-white, $color-white);
      }

      &:nth-child(odd) {
        top: 30px;
        position: relative;

        @media (max-width: 991px) {
          top: 22px;
        }

        @media (max-width: 767px) {
          top: unset;
        }
      }
    }

    &-card-icon {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      z-index: 1;
      transition: $transition;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        filter: unset;
        transition: $transition;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background-image: linear-gradient(225deg, transparentize($color-dusty-orange, 0.85), transparentize($color-pinky-red, 0.85));
        transition: $transition;
        opacity: 1;
      }
    }

    &-card-title {
      margin-top: 0;
      margin-bottom: 0;
      display: inline-block;
      background-image: linear-gradient(to right, $color-pinky-red-two, $color-dusty-orange-three);
      font-family: $ff-tt-font;
      font-size: 22px;
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: normal;
      transition: $transition;
      background-clip: text;
      -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
      -webkit-text-fill-color: transparent;

      @media (max-width: 991px) {
        max-width: 213px;
        font-size: 26px;
      }

      @media (max-width: 499px) {
        max-width: 180px;
        font-size: 22px;
      }
    }
  }


  /*=====  End of Benefits  ======*/


  /*==================================
  =            Industries            =
  ==================================*/

  &-industries {
    padding-bottom: 194px;
    position: relative;
    z-index: 1;

    &-inner-slider {
      padding-bottom: 90px;

      .swiper-button-prev,
      .swiper-button-next {
        top: inherit;
        bottom: 0;
        border-radius: 6px;
        width: 60px;
        height: 60px;
        background-color: rgba($color-dark-three, 0.05);
      }

      .swiper-button-prev::after {
        background-image: url('../images/arrow-left.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        content: '';
      }

      .swiper-button-next::after {
        background-image: url('../images/arrow-right.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        content: '';
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        width: 14px;
        height: 10px;
      }
    }

    @media (max-width: 1199px) {
      padding-bottom: 140px;
    }

    @media (max-width: 991px) {
      padding-bottom: 90px;
    }

    @media (max-width: 767px) {
      padding-bottom: 70px;
    }

    @media (max-width: 499px) {
      padding-bottom: 50px;
    }

    &::after {
      content: '';
      width: 91.7em;
      height: 91.7em;
      display: block;
      position: absolute;
      left: -40em;
      top: -29em;
      border-radius: 50%;
      background-image: url('../images/industries-circles.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      z-index: -1;
      font-size: 10px;

      @media (max-width: 1199px) {
        font-size: 9px;
      }

      @media (max-width: 991px) {
        display: none;
      }
    }

    &-content {
      max-width: 737px;
    }

    &-title {
      margin-bottom: 19px;

      @media (max-width: 991px) {
        margin-bottom: 15px;
      }
    }

    &-text {
      margin-bottom: 43px;

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
        font-family: $ff-tt-font;
        font-size: 22px;
        font-weight: normal;
        line-height: 1.83;
        letter-spacing: normal;
        color: $color-grey-blue;

        @media (max-width: 1199px) {
          font-size: 20px;
        }

        @media (max-width: 991px) {
          font-size: 18px;
        }

        @media (max-width: 767px) {
          line-height: 1.5;
        }
      }
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
      justify-content: space-between;

      @media (max-width: 991px) {
        grid-column-gap: 15px;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
      }
    }

    &-listItem {
      position: relative;
      z-index: 1;
      overflow: hidden;
      border-radius: 8px;
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 71%;

      @media (max-width: 767px) {
        padding-bottom: 76%;
      }

      @media (max-width: 499px) {
        padding-bottom: 56%;
      }
    }

    &-listItem-image {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(to bottom, rgba(41, 47, 59, 0) 50%, $color-dark-three);
        transition: all 0.3s;
      }
    }

    &-listItem-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 40px 30px 36px 41px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      @media (max-width: 1199px) {
        padding: 30px;
      }

      @media (max-width: 991px) {
        padding: 20px;
      }

      @media (max-width: 767px) {
        padding: 35px 30px 20px;
      }

      @media (max-width: 499px) {
        padding: 31px 25px 20px;
      }

      @media (max-width: 374px) {
        padding: 31px 20px 15px;
      }
    }

    &-listItem-title {
      margin-top: 0;
      margin-bottom: 0;
      font-family: $ff-tt-font;
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: normal;
      color: $color-white;

      @media (max-width: 1199px) {
        font-size: 26px;
      }

      @media (max-width: 991px) {
        font-size: 22px;
      }

      @media (max-width: 767px) {
        font-size: 30px;
      }

      @media (max-width: 499px) {
        max-width: 285px;
        font-size: 26px;
      }

      @media (max-width: 374px) {
        max-width: 250px;
        font-size: 24px;
      }
    }
  }
}

/*=====================================
  =            Our successes            =
  =====================================*/

.home-successes {
  text-align: center;
  padding-top: 89px;
  padding-bottom: 69px;
  position: relative;
  z-index: 8;

  @media (max-width: 991px) {
    padding-top: 70px;
    padding-bottom: 50px;
  }

  @media (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 45px;
  }

  @media (max-width: 374px) {
    padding-top: 44px;
    padding-bottom: 28px;
  }

  &-title {
    margin-bottom: 20px;

    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
  }

  &-text {
    margin-bottom: 26px;

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-family: $ff-tt-font;
      font-size: 30px;
      font-weight: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: $color-dark-three;
    }

    @media (max-width: 991px) {
      p {
        font-size: 28px;
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;

      p {
        line-height: 1.36;
      }
    }

    @media (max-width: 499px) {
      max-width: 335px;
      margin: 0 auto 20px;

      p {
        font-size: 24px;
      }
    }

    @media (max-width: 374px) {
      p {
        font-size: 22px;
      }
    }
  }

  &-subtitle {
    margin-top: 0;
    margin-bottom: 13px;
    display: inline-block;
    position: relative;
    font-family: $ff-tt-font;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;

    @media (max-width: 991px) {
      margin-bottom: 5px;

      font-size: 8px;
    }

    @media (max-width: 767px) {
      margin-bottom: 10px;

      font-size: 6px;
    }

    &-text {
      font-size: 7em;
      background-clip: text;
      -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(257deg, $color-dusty-orange, $color-pinky-red);
    }

    &::after {
      content: '+';
      font-size: 5em;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      color: $color-dusty-orange-two;
      position: absolute;
      left: 100%;
      top: -0.25em;
      margin-left: 0.07em;
    }

    &::before {
      content: '$';
      font-size: 3.2em;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: $color-strawberry;
      position: absolute;
      right: 100%;
      top: 0.08em;
      margin-right: 0.29em;
    }
  }

  &-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -20px -35px 46px;
    font-size: 10px;

    @media (max-width: 1199px) {
      font-size: 9px;
    }

    @media (max-width: 991px) {
      font-size: 7px;
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 14em);
      font-size: 10px;
      grid-gap: 1em 7.2em;
    }

    @media (max-width: 499px) {
      font-size: 8px;
    }

    @media (max-width: 374px) {
      grid-gap: 1em 5.2em;
    }
  }

  &-logo {
    font-size: inherit;
    width: 14em;
    height: 8.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 35px 0;

    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }

    &.logo-klm {
      img {
        transform: translateY(-8px);
      }
    }
  }
}

/*=====  End of Our successes  ======*/

.swiper-container.home-industries-inner-slider {
  overflow: visible;

  .swiper-wrapper {
    padding-right: 200%;

    @include mq('tablet', max) {
      padding-right: 0;
    }
  }

  .container {
    height: 90px;
    position: relative;
  }
}

.swiper-slide-next {
  + .swiper-slide {
    ~ .swiper-slide {
      position: relative;
      opacity: 0.5;
      transition: all 0.3s;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        opacity: 0.5;
        border-radius: 8px;
        background-image: linear-gradient(to bottom, rgba(41, 47, 59, 0) 50%, $color-dark-three);
        content: '';
        transition: all 0.3s;
      }
    }
  }
}

.news-section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 700px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .home-industries-title,
  .home-industries-subtitle {
    text-align: center;
  }

  .home-benefits-bg {
    z-index: 1;
    background: transparent;

    @media (max-width: 700px) {
      background: $color-pale-grey-three;
    }

    &-circle {
      z-index: 2;
      top: auto;
      bottom: -49.2em;
    }

    &::before {
      display: none;
    }

    &::after {
      bottom: calc(100% + 3vw);
      top: inherit;
      background: $color-pale-grey-three;
    }
  }

  .home-industries-subtitle {
    margin-bottom: 53px;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.home-page {
  .part-footer {
    position: relative;
    overflow: hidden;

    &__bg::before {
      background-color: $color-pale-grey-three;
    }

    .home-benefits-bg-circle {
      display: block;
      z-index: 2;
      top: -42em;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  [role=main] {
    padding-bottom: 0;
  }
}

.home-industries {
  &-listItem:hover {
    .home-industries {
      &-listItem-image::after {
        opacity: 0.8;
        transition: all 0.3s;
      }
    }
  }
}
