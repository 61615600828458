.contact-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &-item {
    width: 100%;

    @include mq('tablet') {
      &:first-child {
        width: 33.3333%;
      }

      &:last-child {
        width: 66.6666%;
      }
    }

    @include mq('tablet', max) {
      &:first-child {
        margin-bottom: 50px;
      }
    }

    h3 {
      font-family: $ff-tt-font;
      font-size: 30px;
      font-weight: 600;
      line-height: 1.13;
      color: $color-dark-three;
      margin-bottom: 21px;
    }

    h4 {
      font-family: $ff-tt-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
      color: $color-dull-orange;
    }

    p {
      font-family: $ff-tt-font;
      font-size: 18px;
      line-height: 2;
      color: $color-grey-blue;
    }

    &-wrapp {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: -40px;

      &-item {
        width: 50%;
        margin-top: 40px;

        @include mq('phablet', max) {
          width: 100%;
        }
      }
    }
  }

  a {
    color: $color-grey-blue;
    text-decoration: none;

    &:hover,
    &:focus {
      color: darken($color-dusty-orange, 10%);
    }
  }
}

.contact-title {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.33;
  color: $color-grey-blue;
  padding-top: 0;

  @include mq('tablet') {
    font-size: 30px;
    padding-top: 47px;
  }

  p {
    font-size: 22px;
    font-weight: 400;
    line-height: 1.33;
    color: $color-grey-blue;

    @include mq('tablet') {
      font-size: 30px;
    }
  }
}
