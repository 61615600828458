// General Colors
$color-white: #fff;
$color-pale-grey-three: #f3f5f8;
$color-dark-three: #292f3b;
$color-grey-blue: #626e84;
$color-dusty-orange: #ed8334;
$color-pinky-red: #eb2e4c;
$color-very-light-blue: #e3e8f0;
$color-strawberry: #eb344a;
$color-dusty-orange-two: #ed7d36;
$color-light-grey-blue: #9faabd;
$color-pinky-red-two: #ea2e4a;
$color-dusty-orange-three: #ea8034;
$color-pale-red: #ec4f43;
$color-orange-two: #f37c22;
$color-cloudy-blue: #b6bece;
$color-dull-orange: #ee7b27;
$color-light-grey-blue: #9faabd;
$color-light-green: #36cf76;

// Animation
$transition: 0.25s;

// Border radius
$border-radius: 10px;

// Grid Size
$grid-size: 1128px;

// Grid Size - Wrapper Offsets
$grid-offset-mobile: 16px;
$grid-offset-tablet: 16px;
$grid-offset-desktop: 16px;

// Media Query Width
$mqw-mobile: 320px;
$mqw-tablet: 768px;
$mqw-desktop: 1024px;
$mqw-wrapper: $grid-size;

//Font Family
$ff-system: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
$ff-tt-font: 'TTCommons', #{$ff-system};
$ff-calibre-font: 'Calibre', #{$ff-system};

$breakpoints: (
  'phone': 374px,
  'phablet': 499px,
  'tablet': 767px,
  'tablet-wide': 991px,
  'desktop-sm': 1199px,
  'desktop-md': 1280px,
  'desktop-wide': 1441px
);
