.blogs-listing {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding-bottom: 40px;

  @include mq('tablet') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq('desktop-sm') {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 80px;
  }

  &-action {
    text-align: center;
  }
}

