//@-ms-viewport {
//  width: device-width;
//}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 320px) {
  .container {
    max-width: 500px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 374px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 768px;
    max-width: none;
  }
}
@media (min-width: 992px) {
  .container {
    width: 962px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1150px;
    padding-left: 0;
    padding-right: 0;
  }

  .container-wide {
    width: 100%;
    max-width: 1350px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 374px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.clearfix,
.clearfix::before,
.clearfix::after,
.container::before,
.container::after,
.container-fluid::before,
.container-fluid::after,
.row::before,
.row::after {
  display: table;
  content: '';
}

.clearfix::after,
.container::after,
.container-fluid::after,
.row::after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.double {
  &__content {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 15px 50px;

    @include mq('tablet') {
      display: flex;
      justify-content: space-between;
      flex-wrap: inherit;
      flex-direction: row;
      padding: 0 0 112px;
    }

    &.align-content-center {
      align-items: center;
    }

    &-item {
      width: 100%;
      margin-top: 50px;

      @include mq('tablet') {
        width: 50%;
        margin-top: 0;
      }
    }

    &-item:first-child {
      &.padding {
        padding-right: 0;

        @include mq('tablet') {
          padding-right: 160px;
        }
      }
    }

    &-item:last-child {
      &.padding {
        padding-left: 0;

        @include mq('tablet') {
          padding-left: 160px;
        }
      }
    }
  }
}

.pt-37 {
  padding-top: 37px;
}
