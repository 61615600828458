.js-filte-btn {
  visibility: hidden;
  display: none;
}

.filter {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  >* {
    margin-bottom: 16px;
  }

  >*:not(:last-child) {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  label {
    cursor: pointer;
  }

  &-box {
    position: relative;
    z-index: 5;

    &:hover {
      .filter-list {
        opacity: 1;
        visibility: visible;
        z-index: 5;
      }
    }
  }

  &-list {
    position: absolute;
    list-style: none;
    background: $color-white;
    box-shadow: 0 30px 200px rgba(18, 21, 27, 0.2);
    border-radius: 6px;
    top: calc(100% + 6px);
    left: 0;
    padding: 24px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: 0.35s ease;

    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $color-dark-three;

    li {
      white-space: nowrap;
    }

    li:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.button.filter-btn {
  padding-left: 16px;
  padding-right: 16px;
  display: block;
}

.radio-filter {
  cursor: pointer;

  &:hover {
    color: $color-orange-two;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0;
  }
}

.checkbox-filter {
  position: relative;
  padding: 0 0 0 32px;
  cursor: pointer;
  display: block;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 24px;
    height: 24px;
    display: block;
    transition: all 0.3s ease;
  }

  &::before {
    border: 2px solid $color-dark-three;
    border-radius: 6px;
  }

  &::after {
    background-image: url('../images/checked.svg');
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
  }

  &.checked {
    &::after {
      opacity: 1;
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0;
  }
}

.filter-link {
  font-size: 18px;
  color: $color-dusty-orange;
  text-decoration: none;
  position: relative;

  span {
    display: inline-block;
    color: $color-dusty-orange;
    background-clip: text;
    -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
    -webkit-text-fill-color: transparent; /* stylelint-disable-line  property-no-unknown */
    background-image: linear-gradient(to right, #ea2e4a, #ea8034 64%);
    text-fill-color: transparent; /* stylelint-disable-line  property-no-unknown */
    position: relative;
    z-index: 2;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    transition: 0.5s ease-in-out color;
  }

  &:hover,
  &:focus {
    color: darken($color-dusty-orange, 10%);

    span {
      color: $color-dark-three;
      background-image: linear-gradient(to right, #ea8034, #ea2e4a 64%);
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 0;
  }
}



