.blog {
  padding-top: 100px;
  padding-bottom: 40px;

  @include mq('tablet') {
    padding-top: 180px;
    padding-bottom: 60px;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-grey {
    @include mq('tablet-wide') {
      padding-left: 20px;
      padding-right: 20px;
      width: 60px;
      text-align: center;
    }
  }

  &-heading {
    h1 {
      color: $color-dark-three;
      line-height: 1;
      font-weight: 600;
      margin-top: 24px;
      margin-bottom: 24px;

      @include mq('tablet') {
        font-size: 70px;
        margin-bottom: 50px;
      }
    }
  }

  &-type {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $color-light-grey-blue;
    margin-bottom: -6px;
    margin-top: 30px;
    padding: 8px 16px;
    letter-spacing: normal;
    display: inline-block;
    background-color: $color-pale-grey-three;

    @include mq('tablet') {
      margin-top: 40px;
    }

    span {
      background: linear-gradient(225deg, #ed8334 0%, #eb2e4c 100%), #292f3b;
      -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
      -webkit-text-fill-color: transparent; /* stylelint-disable-line  property-no-unknown */
      background-clip: text;
      text-fill-color: transparent; /* stylelint-disable-line  property-no-unknown */
      text-decoration: none;
    }
  }

  &-data {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $color-light-grey-blue;
    margin-bottom: 0;
    margin-top: -16px;
    display: block;

    @include mq('tablet') {
      margin-top: -42px;
      margin-bottom: -24px;
    }
  }

  &-cover {
    img {
      display: block;
      max-width: 100%;
      border-radius: 8px;
      overflow: hidden;
      height: auto;
    }
  }

  &-excerpt {
    margin-top: 24px;

    @include mq('tablet') {
      margin-top: 64px;
    }

    * {
      font-size: 18px;
      line-height: 1.67;
      font-weight: normal;

      @include mq('tablet') {
        font-size: 24px;
      }
    }
  }

  &-body {
    font-size: 16px;
    margin-top: 40px;

    @include mq('tablet') {
      margin-top: 60px;
      font-size: 18px;
    }
  }
}
