.lazyload,
.lazyloading {
  background-image: url('../images/loader.svg');
  background-size: 1.5rem;
  background-position: center;
  background-repeat: no-repeat;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
