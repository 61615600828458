.text-block {
  * {
    font-size: 1em;
    line-height: 2;
    color: $color-dark-three;
  }

  img {
    height: auto;
  }

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2,
  h3,
  strong {
    font-weight: 600;
  }

  h2 {
    font-size: 1.5em;
  }

  h3,
  strong {
    font-size: 1.11em;
  }

  > * + * {
    margin-top: 1.5em;

    @include mq('tablet') {
      margin-top: 1.75em;
    }
  }

  h2 + *,
  h3 + *,
  strong + * {
    margin-top: 0.25em;
  }

  ul,
  ol {
    padding-left: 16px;
    margin-left: 0;
  }

  li {
    line-height: 1.85;
  }

  li + li {
    margin-top: 0.5em;
  }

  a {
    color: $color-dusty-orange;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: darken($color-dusty-orange, 10%);
    }
  }
}

.blog .text-block .button-grey {
  width: auto;

  &:hover {
    color: $color-dark-three;
  }
}
