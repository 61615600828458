.results {
  margin-bottom: 112px;

  .container {
    min-height: 297px;
    padding: 46px 15px 76px;
    border-radius: 8px;
    background-color: $color-dark-three;
    text-align: center;
    background-image: url('../images/results-bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
  }

  &__title {
    font-family: $ff-tt-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 2;
    text-align: center;
    color: $color-dull-orange;
    margin-bottom: 15px;
  }

  &__sub-title {
    font-family: $ff-tt-font;
    font-size: 22px;
    line-height: 1.33;
    color: $color-white;
    max-width: 920px;
    display: inline-block;

    @include mq('tablet') {
      font-size: 30px;
    }
  }
}

.about {
  &__info {
    div {
      margin-bottom: 8px;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    figcaption {
      font-family: $ff-tt-font;
      font-size: 18px;
      line-height: 2;
      color: $color-cloudy-blue;
    }
  }
}

.top-border {
  padding-top: 50px;
  border-top: 1px solid $color-very-light-blue;

  @include mq('tablet') {
    padding-top: 120px;
    border-top: 1px solid $color-very-light-blue;
  }
}

.double {
  &__content {
    p {
      font-size: 18px;
      line-height: 2;
      color: $color-grey-blue;

      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }

    &-item {
      .title-gradient {
        margin-bottom: 22px;
      }
    }
  }
}

.text-block {
  + .button {
    margin-top: 38px;
  }
}
