/**
 *
 * Buttons
 *
 */

.button {
  display: inline-block;
  font-family: $ff-tt-font;
  cursor: pointer;
  outline: none;
  border: inherit;
  border-radius: 6px;
  transition: $transition;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;

  &.button-small {
    padding: 12px 29px 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;

    @media (max-width: 991px) {
      font-size: 16px;
    }

    @media (max-width: 767px) {
      padding: 10px 18px 8px;
    }

    @media (max-width: 374px) {
      font-size: 14px;
    }
  }

  &.button-large {
    padding: 21px 40px 18px;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;

    @media (max-width: 991px) {
      padding: 18px 32px 15px;
      font-size: 16px;
    }

    @media (max-width: 767px) {
      padding: 16px 20px 12px;
    }

    @media (max-width: 374px) {
      padding: 16px 17px 12px;
      font-size: 14px;
    }
  }

  &.button-medium {
    padding: 21px 31px 18px;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;

    @media (max-width: 991px) {
      padding: 18px 25px 15px;
      font-size: 16px;
    }

    @media (max-width: 767px) {
      padding: 16px 15px 12px;
    }

    @media (max-width: 374px) {
      padding: 16px 15px 12px;
      font-size: 14px;
    }
  }

  &.button-orange {
    color: $color-white;
    letter-spacing: -0.3px;
    position: relative;
    z-index: 1;
    background-image: linear-gradient(255deg, $color-dusty-orange, $color-pinky-red);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      background-image: linear-gradient(255deg, lighten($color-dusty-orange, 4%), lighten($color-pinky-red, 4%));
      transition: $transition;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      background-image: linear-gradient(255deg, lighten($color-dusty-orange, 6%), lighten($color-pinky-red, 6%));
      transition: $transition;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &:active,
    &:focus {
      &::after {
        opacity: 1;
      }
    }
  }

  &.button-orange-2 {
    color: $color-white;
    letter-spacing: -0.3px;
    position: relative;
    z-index: 1;
    //background-image: linear-gradient(255deg, $color-dusty-orange, $color-pinky-red);
    background-image: linear-gradient(225deg, $color-dusty-orange 0%, $color-pinky-red 100%);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      background-image: linear-gradient(255deg, lighten($color-dusty-orange, 4%), lighten($color-pinky-red, 4%));
      transition: $transition;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      background-image: linear-gradient(255deg, lighten($color-dusty-orange, 6%), lighten($color-pinky-red, 6%));
      transition: $transition;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &:active,
    &:focus {
      &::after {
        opacity: 1;
      }
    }
  }

  &.button-white {
    color: $color-white;
    background-color: transparentize($color-white, 0.8);

    &:hover {
      background-color: transparentize($color-white, 0.7);
    }

    &:active,
    &:focus {
      background-color: transparentize($color-white, 0.6);
    }
  }

  &.button-white-dark {
    color: $color-dark-three;
    background-color: $color-white;

    &:hover {
      background-color: transparentize($color-white, 0.7);
    }

    &:active,
    &:focus {
      background-color: transparentize($color-white, 0.6);
    }
  }

  &.button-grey {
    color: $color-dark-three;
    background-color: $color-pale-grey-three;
    text-decoration: none;

    &:hover {
      background-color: lighten($color-pale-grey-three, 1%);
    }

    &:active,
    &:focus {
      background-color: darken($color-pale-grey-three, 3%);
    }
  }

  &.button-grey-dark {
    color: $color-dark-three;
    background-color: rgba($color-dark-three, 0.2);

    &:hover {
      background-color: rgba($color-dark-three, 0.3);
    }

    &:active,
    &:focus {
      background-color: rgba($color-dark-three, 0.4);
    }
  }

  &.button-wide {
    width: 100%;
    text-align: center;
  }

  &.button-pages {
    min-width: 34px;
    height: 34px;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;

    @include mq('tablet') {
      height: 40px;
      min-width: 40px;
    }

    svg {
      width: 14px;
      height: 14px;

      @include mq('tablet') {
        width: 16px;
        height: 16px;
      }
    }

    &.disabled {
      cursor: inherit;

      path {
        fill: $color-light-grey-blue;
      }
    }
  }

  .button-icon:last-child {
    padding-left: 8px;
    display: inline-block;
    height: 13px;
    overflow: hidden;
  }
}

