.toggle {
  position: relative;
  overflow: hidden;
  width: 163px;
  height: 40px;
  background: $color-pale-grey-three;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.5;

  &__checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  &__layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }

  &__knobs,
  &__layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__knobs {
    z-index: 2;
  }

  &__knobs span {
    display: inline-block;
    left: 4px;
    color: $color-white;
    z-index: 1;
  }

  &__checkbox:checked + &__knobs::before {
    left: auto;
    right: 4px;
  }

  &__knobs::before {
    content: '';
    left: 4px;
    background: $color-white;
  }

  &__knobs::before,
  &__knobs::after,
  &__knobs span {
    position: absolute;
    top: 4px;
    text-align: center;
    transition: 0.3s ease all;
    padding: 5px 8px;
    gap: 8px;
    width: 76px;
    height: 32px;
    border-radius: 6px;
  }

  &__checkbox:not(:checked) + &__knobs span,
  &__checkbox:checked + &__knobs::after {
    color: $color-dark-three;
    display: inline-block;
    background-clip: text;
    -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #ea2e4a, #ea8034 64%);
  }

  &__checkbox:checked + &__knobs span,
  &__knobs::after {
    content: 'Annually';
    right: 4px;
    color: $color-cloudy-blue;
  }
}

