*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
input,
select,
button,
textarea {
  font-family: $ff-tt-font;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: $color-grey-blue;
}

html,
body {
  background-color: $color-white;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1em;

  font-family: $ff-tt-font;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: $color-grey-blue;

  @media (max-width: 374px) {
    font-size: 18px;
  }
}

img {
  vertical-align: middle;
}

a {
  text-decoration: none;
  transition: $transition;
  outline: none;
}

figure,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

.lity-close {
  position: absolute;
  background-color: $color-dusty-orange;
  border-radius: 50%;
  top: -18px;
  right: -18px;
  transition: background 0.2s ease-in-out;
  text-shadow: none !important;

  font-family: $ff-tt-font !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.1 !important;
  font-size: 35px;
  padding-left: 1px !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $color-dusty-orange;
    top: -18px;
  }

  @media (max-width: 1199px) {
    right: 0;
    top: -35px;

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($color-dusty-orange, 10%);
      top: -35px;
    }
  }
}

.lity-wrap {
  background-color: transparentize($color-dark-three, 0.5);
}


[role='main'] {
  flex-grow: 1;
  padding-bottom: 40px;

  @include desktop {
    padding-bottom: 64px;
  }
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: $mqw-wrapper + ($grid-offset-desktop * 2);
  padding-left: $grid-offset-mobile;
  padding-right: $grid-offset-mobile;

  @include tablet {
    padding-left: $grid-offset-tablet;
    padding-right: $grid-offset-tablet;
  }

  @include desktop {
    padding-left: $grid-offset-desktop;
    padding-right: $grid-offset-desktop;
  }
}

.section {
  &__title {
    margin-top: 0;
    margin-bottom: 20px;
    font-family: $ff-tt-font;
    font-size: 60px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;
    color: $color-white;

    @media (max-width: 1199px) {
      font-size: 50px;
    }

    @media (max-width: 991px) {
      font-size: 46px;
    }

    @media (max-width: 767px) {
      font-size: 50px;
    }

    @media (max-width: 500px) {
      font-size: 42px;
    }

    @media (max-width: 374px) {
      font-size: 32px;
    }
  }

  &__titleSmall {
    margin-top: 0;
    margin-bottom: 0;
    font-family: $ff-tt-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: normal;
    color: $color-light-grey-blue;
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 15px;
    font-family: $ff-tt-font;
    font-size: 50px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $color-dark-three;

    @media (max-width: 1199px) {
      font-size: 46px;
    }

    @media (max-width: 991px) {
      font-size: 36px;
      margin-bottom: 5px;
    }

    @media (max-width: 767px) {
      font-size: 34px;
      margin-bottom: 10px;
    }

    @media (max-width: 499px) {
      font-size: 30px;
    }

    @media (max-width: 374px) {
      font-size: 26px;
    }
  }
}

.title-gradient {
  display: inline-block;
  font-size: 20px;
  background-clip: text;
  -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #ea2e4a, #ea8034 64%);
}

.text-gradient {
  display: inline-block;
  background-clip: text;
  -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #ea2e4a, #ea8034 64%);
}

.text-center {
  text-align: center;
}

.margin-center {
  margin: 0 auto;
  text-align: center;
}

.hidden {
  overflow: hidden;
  height: 100vh;
}

.b-top {
  padding-top: 50px;
  border-top: 1px solid $color-very-light-blue;

  @include mq('tablet-wide') {
    padding-top: 100px;
    border-top: 1px solid $color-very-light-blue;
  }
}

.is-hide {
  display: none !important;
}

.phablet-down {
  @media (max-width: 499px) {
    display: inherit !important;
  }

  @media (min-width: 500px) {
    display: none !important;
  }
}

.phablet-up {
  @media (max-width: 499px) {
    display: none !important;
  }

  @media (min-width: 500px) {
    display: inherit !important;
  }
}
