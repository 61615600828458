.pages {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-bottom: 100px;
  margin: 0 auto;

  @include mq('tablet') {
    display: flex;
  }

  li:not(:last-child) {
    margin-right: 10px;

    @include mq('tablet') {
      margin-right: 16px;
    }
  }

  span {
    cursor: inherit;
  }
}
