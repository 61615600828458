.pricing-plan {
  $parent: &;

  box-shadow: 0 30px 200px rgba(18, 21, 27, 0.08);
  margin-top: 40px;
  background: $color-white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  &__content {
    padding: 16px;
    width: 100%;

    @include mq('tablet') {
      padding: 24px;
    }
  }

  &__badge {
    display: block;
    padding: 8px;
    background-color: $color-white;
    text-align: center;
    margin: 2px 2px 0;
    border-radius: 8px 8px 0 0;

    p {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: $color-dark-three;
  }

  &__price {
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
    display: flex;
    align-items: flex-end;
    color: $color-dark-three;
  }

  &__cta {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  &__desc {
    margin-top: 8px;
  }

  &__desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  &__note {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  &__desc,
  &__price-postfix,
  &__feature {
    color: $color-cloudy-blue;
  }

  &__price-postfix {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 14px;
    margin-left: 4px;
  }

  &__price,
  &__cta,
  &__features {
    margin-top: 24px;
  }

  &__features > * + * {
    margin-top: 16px;
  }

  &__feature {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    position: relative;
    padding-left: 30px;

    svg {
      position: absolute;
      left: 0;
      top: 6px;
    }
  }

  &--recommended {
    color: $color-white;
    margin-top: 0;
    background-color: $color-dusty-orange;
    background-image: linear-gradient(225deg, $color-dusty-orange 0%, $color-pinky-red 100%);

    //#{$parent}__content {
    //
    //}

    #{$parent}__heading ,
    #{$parent}__desc ,
    #{$parent}__note ,
    #{$parent}__feature p,
    #{$parent}__price,
    #{$parent}__price-postfix,
    #{$parent}__price {
      color: $color-white;
    }

  }
}
