.fui {
  &-fieldset {
    padding: 0;
    border: none; /* stylelint-disable-line */
  }

  &-row {
    gap: 0.5rem;
  }

  &-row + &-row {
    margin-top: 0.5rem;
  }

  &-checkbox {
    position: relative;

    input {
      position: absolute;
      left: 0;
      height: 16px;
      width: 16px;
      top: 3px;
      border-radius: 2px;
    }

    label {
      padding-left: 30px;
    }
  }

  &-alert-top-form {
    font-size: 30px;
    line-height: 1.25;

    span {
      font-size: 22px;
    }
  }

  .error {
    position: absolute;
    top: 100%;
    left: 0;
    line-height: 1.5;
    font-size: 14px;
    font-family: $ff-tt-font;
    color: #eb2e4c;
    padding-left: 5px;
  }

  &-error-message {
    margin-top: 4px;
    line-height: 1.5;
    font-size: 16px;
    font-family: $ff-tt-font;
    color: #eb2e4c;
  }

  &-input:not([type=checkbox]):not([type=radio]),
  &-select {
    line-height: 1;
    width: 100%;
    display: block;
    padding: 16px 22px 14px;
    outline: none;
    border-radius: 6px;
    border: 1px solid $color-very-light-blue;
    font-size: 18px;
    font-weight: 500;
    color: $color-grey-blue;
    letter-spacing: -0.1px;
    background-color: #fff;

    @media (max-width: 767px) {
      padding: 12px 18px 10px;
    }

    &.error {
      border-color: #eb2e4c;
    }
  }

  &-select {
    padding: 16px 22px 14px;
  }

  &-btn {
    color: #fff;
    letter-spacing: -0.3px;
    position: relative;
    z-index: 1;
    background-image: linear-gradient(255deg, $color-dusty-orange, $color-pinky-red);
    padding: 21px 31px 18px;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    display: inline-block;
    font-family: $ff-tt-font;
    cursor: pointer;
    outline: none;
    border: inherit;
    border-radius: 6px;
    transition: 0.25s;
    overflow: hidden;
    white-space: nowrap;
  }

  &-page-row {
    margin-bottom: 20px;
  }

  &-label {
    line-height: 1;
  }
}
