.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background-color: transparentize($color-dark-three, 0.3);
  overflow-y: auto;
  flex-direction: column;
  justify-content: flex-start;
  display: none;

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    padding: 20px 0;
    min-height: calc(100vh - 40px);
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @media (max-width: 767px) {
      align-items: flex-start;
      padding: 90px 0;
      min-height: calc(100vh - 120px);
    }
  }

  &__closeOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__close {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 19px;
    right: 19px;
    cursor: pointer;
    z-index: 1;

    &-line {
      width: 100%;
      height: 3px;
      display: block;
      position: absolute;
      left: 0;

      background: #b5becf;
      transition: all $transition ease-in-out;

      &.line-1 {
        top: 5px;

        transform: rotate(45deg);
      }

      &.line-2 {
        bottom: 6px;

        transform: rotate(-45deg);
      }
    }
  }

  &__form {
    &-group {
      position: relative;
    }
  }
}


/*========================================
=            Download CS form            =
========================================*/

.download-modal {
  &__inner {
    position: relative;
    width: 500px;
    max-width: 100%;
    padding: 46px 50px 50px;
    border-radius: 8px;
    overflow: hidden;
    background-color: $color-white;
    z-index: 2;

    @media (max-width: 767px) {
      padding: 46px 25px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 28px;

    font-family: $ff-tt-font;
    font-size: 30px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: $color-dark-three;

    @media (max-width: 767px) {
      margin-bottom: 20px;

      font-size: 26px;
    }
  }

  .modal {
    &__form-group {
      margin-bottom: 40px;

      @media (max-width: 767px) {
        margin-bottom: 25px;
      }
    }
  }

  .fui-page-row {
    margin-bottom: 40px;
  }
}

/*=====  End of Download CS form  ======*/


/*==========================================
=            Request demo modal            =
==========================================*/

.requestdemo-modal {
  &__inner {
    position: relative;
    width: 1020px;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 30px 200px 0 rgba(18, 21, 27, 0.08);
    background-color: $color-white;
    z-index: 2;

    @media (max-width: 991px) {
      width: 768px;
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__form {
    padding: 70px 70px 80px;
    background-color: $color-pale-grey-three;

    @media (max-width: 991px) {
      padding: 70px 40px;
    }

    @media (max-width: 767px) {
      padding: 40px 30px;
    }

    @media (max-width: 374px) {
      padding: 40px 23px;
    }
  }

  &__content {
    padding: 68px 80px 80px 70px;

    @media (max-width: 991px) {
      padding: 68px 40px 80px;
    }

    @media (max-width: 767px) {
      padding: 50px 30px 30px;
    }

    @media (max-width: 374px) {
      padding: 50px 23px 30px;
    }
  }

  &__text {
    p {
      margin-bottom: 0;
      font-family: $ff-tt-font;
      font-size: 22px;
      font-weight: normal;
      line-height: 1.64;
      letter-spacing: normal;
      color: $color-grey-blue;
    }

    @media (max-width: 991px) {
      p {
        font-size: 20px;
      }
    }

    @media (max-width: 374px) {
      p {
        font-size: 18px;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 17px;

    font-family: $ff-tt-font;
    font-size: 50px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;
    color: $color-dark-three;

    @media (max-width: 1199px) {
      font-size: 46px;
    }

    @media (max-width: 991px) {
      font-size: 36px;
    }

    @media (max-width: 767px) {
      font-size: 34px;
      margin-bottom: 10px;
    }

    @media (max-width: 499px) {
      font-size: 30px;
    }

    @media (max-width: 374px) {
      font-size: 26px;
    }
  }

  .modal {
    &__form-group {
      margin-bottom: 20px;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }

  button {
    margin-top: 10px;
  }

}

