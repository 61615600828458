.pricing {
  &__subheading {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  &__heading {
    font-weight: 400;
    font-size: 50px;
    line-height: 50px;
    margin-top: 16px;
  }

  &__plans {
    margin-top: 24px;
  }

  &__text * {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.36;

    @include tablet() {
      line-height: 40px;
    }
  }

  &__switcher {
    margin: 46px auto 0;
  }

  &__note {
    margin-top: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-light-green;
  }
}
