.landing {
  &-code {
    margin-top: 40px;

    button {
      width: auto !important;
      background-image: linear-gradient(255deg, #ef8e47, #ed415c) !important;
      border-width: 0 !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      height: 42px !important;

      @media (min-width: 768px) {
        height: 60px !important;
      }

      &:hover {
        background-image: linear-gradient(255deg, #ef9450, #ee4a64) !important;
      }
    }
  }
}
