.video-box {
  $parent: &;

  position: relative;

  video {
    background-color: #fff;
    position: relative;
    z-index: 0;
    cursor: pointer;
  }

  video[poster] {  /* stylelint-disable-line */
    height: 100%;
    width: 100%;
  }

  &__btn {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    height: 4rem;
    width: 4rem;
    transform: translate(-2rem, -2rem);
    border-radius: 100%;
    background-color: $color-dull-orange;
    border: none;  /* stylelint-disable-line */
  }

  &__btn,
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 30px;
    transform: translateX(2px);
  }

  &.is-active {
    #{$parent}__btn {
      display: none;
    }
  }
}
