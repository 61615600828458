$root: '.lang-switcher';

.lang-switcher {
  display: block;
  position: relative;
  z-index: 1;

  &__btn {
    background: none;
    border: unset;
    box-shadow: none;
    height: 24px;

    &::before {
      content: '';
      position: absolute;
      right: -12px;
      z-index: 1;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0;
      border-color: #fff transparent transparent;
    }
  }

  &__content {
    top: 38px;
    left: -10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    transition: 0.35s ease;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    position: absolute;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: -6px;
      left: 16px;
      display: block;
      border-style: solid;
      border-width: 0 8px 8px;
      border-color: transparent transparent #fff;
    }
  }

  &:hover &__btn {
    &::before {
      transform: rotate(180deg);
    }
  }

  &:hover &__content {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  &__btn,
  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 4px;
    font-size: 16px;
    line-height: 1;

    &:hover {
      color: #f37c22;
    }
  }

  &__link,
  &__label {
    color: $color-grey-blue;

    &:hover {
      color: #f37c22;
    }
  }

  &__link-active {
    color: $color-dark-three;
  }

  &__icon {
    height: 20px;
    width: 20px;
    display: block;
  }

  &__label {
    display: block;
    padding-top: 2px;
    margin-left: 8px;
  }

  &__link + &__link {
    margin-top: 2px;
  }
}

.lang-switcher--dark {
  #{$root}__btn {
    &::before {
      border-color: $color-grey-blue transparent transparent;
    }
  }
}



