.blog-card {
  padding: 35px 30px 34px 40px;
  border-radius: 8px;
  box-shadow: 0 30px 200px 0 rgba(18, 21, 27, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-image: linear-gradient(to right, #ea2e4a, #ea8034 64%);
    transition: 0.5s ease-in-out opacity;
    opacity: 0;
    border-radius: 8px;
  }

  &-title,
  &-btn {
    transition: 0.5s ease-in-out color;
  }

  &-title {
    color: $color-dark-three;
    font-size: 1.5rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    font-weight: 600;
    margin-bottom: 50px;
    position: relative;
    z-index: 2;
  }

  &-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $color-light-grey-blue;
    margin-bottom: 4px;
    position: relative;
    z-index: 2;
  }

  &-data {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-light-grey-blue;
    margin-bottom: 4px;
    position: relative;
    z-index: 2;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
  }

  &-btn {
    line-height: 0;

    span {
      display: inline-block;
      color: $color-dusty-orange;
      background-clip: text;
      -webkit-background-clip: text; /* stylelint-disable-line  property-no-vendor-prefix */
      -webkit-text-fill-color: transparent; /* stylelint-disable-line  property-no-unknown */
      background-image: linear-gradient(to right, #ea2e4a, #ea8034 64%);
      text-fill-color: transparent; /* stylelint-disable-line  property-no-unknown */
      position: relative;
      z-index: 2;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.33;
      transition: 0.5s ease-in-out color;
    }
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 1;
    }

    .blog-card-title,
    .blog-card-type,
    .blog-card-data,
    .blog-card-btn,
    .blog-card-btn span {
      color: #fff;
      text-fill-color: unset; /* stylelint-disable-line  property-no-unknown */
      -webkit-text-fill-color: unset; /* stylelint-disable-line  property-no-unknown */
      transition: 0.5s ease-in-out color;
    }
  }
}


